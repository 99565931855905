<template>
  <div>
    <v-overlay :value="isGuideForUser"></v-overlay>
    <!-- VNavigationDrawer - Vuetify -->
    <v-skeleton-loader
      :loading="false"
      transition="scale-transition"
      :boilerplate="false"
      type="table"
      class="mx-auto"
    >
      <v-navigation-drawer
        v-model="isOpen"
        :mini-variant.sync="mini"
        :permanent="!isMobile"
        class="text-left pt-3 border-none"
        :class="[isGuideForUser ? 'pointer-events-none' : '']"
        :expand-on-hover="expandOnHover"
        mobile-breakpoint="960"
        color="#f9f9f9"
        app
        width="280"
        v-bind="$attrs"
      >
        <template>
          <router-link to="/" class="pl-2 flex items-center space-x-1 mb-1">
            <!-- <logo /> -->
            <img src="../../assets/logo/logo.png" alt="logo" class="w-20 mb-5" />
            <p class="text-h7 font-weight-black orange--text mb-4 brand">BẢO ĐẠI ĐƯỜNG</p>
          </router-link>
        </template>
        <!-- VList - Vuetify -->
        <v-list expand nav>
          <!-- Item.vue - molecules component-->
          <v-fade-transition group>
            <template v-for="(item, i) in computedItems">
              <ItemGroup
                v-if="item.children"
                :key="`group-${i}`"
                :item="item"
                :tooltipContent="tooltipContent"
                :enableTooltip="isGuideForUser"
                :active="currentStep === i"
                :currentStep="currentStep"
                :nextStep="nextStep"
              />
              <!-- Item.vue - molecules component-->
              <item
                v-else
                :key="`item-${i}`"
                :item="item"
                :tooltipContent="tooltipContent"
                :enableTooltip="isGuideForUser"
                :active="currentStep === i"
                :currentStep="currentStep"
                :nextStep="nextStep"
              />
            </template>
          </v-fade-transition>
        </v-list>
        <template v-slot:append>
          <!-- Item.vue - molecules component-->
          <item
            v-if="role === 'Admin'"
            :item="{
              title: 'Cài đặt',
              icon: 'mdi-cog-outline',
              to: '/cai-dat',
            }"
          />
          <v-list-item
            v-if="!mini && !isMobile"
            class="h-12 cursor-pointer"
            @click.native="onClickExpand"
          >
            <!--VListItemIcon - Vuetify-->
            <v-list-item-icon>
              <v-icon>mdi-chevron-left</v-icon>
            </v-list-item-icon>
            <!--VListItemContent - Vuetify-->
            <v-list-item-content class="text-lg"> Thu nhỏ </v-list-item-content>
          </v-list-item>
          <span v-if="!mini && !isMobile" class="text-center text-xs text-grey mx-auto">
            <p class="mb-0">Copyright © 2023</p>
            <p>Bản quyền thuộc về Bảo Đại Đường</p>
          </span>
        </template>
      </v-navigation-drawer>
    </v-skeleton-loader>
  </div>
</template>

<script>
// Utilities
import { mapMutations, mapGetters, mapState } from "vuex";
import { EMPTY, STEP_BY_STEP, PATH } from "~/utils/constants";

export default {
  name: "SideBar",

  components: {
    Item: () => import("~/components/molecules/Item"),
    ItemGroup: () => import("~/components/molecules/ItemGroup"),
    logo: () => import("~/components/logos/Logo"),
  },

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      role: EMPTY,
      isLoading: false,
      mini: false,
      items: [
        {
          icon: "mdi-view-dashboard-outline",
          title: "Tổng quan",
          to: PATH.HOME,
          roles: ["Admin", "BacSy", "LeTan", "BocThuoc", "SacThuoc"],
          children: null,
        },
        {
          icon: "mdi-plus",
          title: "Đăng ký",
          to: PATH.REQUEST_DOCTOR,
          roles: ["Admin", "LeTan", "QuanLy"],
          children: null,
        },
        {
          icon: "mdi-account-group-outline",
          title: "Bệnh nhân",
          to: PATH.PATIENT,
          roles: ["Admin", "BacSy", "LeTan", "QuanLy", "BocThuoc", "SacThuoc"],
          children: null,
        },
        {
          icon: "mdi-pill",
          title: "Vị thuốc",
          to: PATH.MEDICINE,
          roles: ["Admin", "QuanLy", "BocThuoc", "BacSy"],
          children: [
            {
              icon: "mdi-manjaro",
              title: "Quản lý",
              to: PATH.MEDICINE,
            },
            {
              icon: "mdi-tag",
              title: "Nhãn dán",
              to: "/nhan-vi-thuoc",
            },
            {
              icon: "mdi-group",
              title: "Nhóm",
              to: "/nhom-vi-thuoc",
            },
          ],
        },
        {
          icon: "mdi-medical-cotton-swab",
          title: "Bài thuốc",
          to: PATH.PRESCRIPTION_BASE,
          roles: ["Admin", "BacSy", "QuanLy"],
          children: [
            {
              icon: "mdi-manjaro",
              title: "Quản lý",
              to: PATH.PRESCRIPTION_BASE,
            },
            {
              icon: "mdi-tag",
              title: "Nhãn dán",
              to: "/nhan-bai-thuoc",
            },
            {
              icon: "mdi-group",
              title: "Nhóm",
              to: "/nhom-bai-thuoc",
            },
          ],
        },
        {
          icon: "mdi-needle",
          title: "Đơn thuốc",
          to: PATH.PRESCRIPTION,
          roles: ["Admin", "BacSy", "BocThuoc", "SacThuoc", "QuanLy"],
          children: null,
        },
        {
          icon: "mdi-medical-bag",
          title: "Lên đơn",
          to: PATH.WAITING_PRESCRIPTION,
          roles: ["Admin", "BacSy", "QuanLy"],
          children: null,
        },
        {
          icon: "mdi-calendar",
          title: "Hẹn khám",
          to: PATH.PRESCRIPTION_BASE,
          roles: ["Admin", "Bacsy", "LeTan"],
          children: [
            {
              icon: "mdi-calendar",
              title: "Lên lịch khám",
              roles: ["Admin", "Bacsy", "LeTan"],
              to: PATH.REQUEST_SCHEDULE,
            },
            {
              icon: "mdi-folder-plus",
              title: "Bác sỹ khám",
              to: PATH.DOCTOR,
              roles: ["Admin", "Bacsy", "LeTan"],
            },
            // {
            //   icon: "mdi-calendar",
            //   title: "Lịch hẹn khám",
            //   to: PATH.APPOINTMENT_SCHEDULE,
            // },
          ],
        },
        {
          icon: "mdi-folder-plus",
          title: "Đơn thuốc cũ",
          to: PATH.CREATE_OLD_PRESCRIPTION,
          roles: ["Admin", "Bacsy"],
          children: null,
        },
        {
          icon: "mdi-account",
          title: "Người dùng",
          to: PATH.ACCOUNT,
          roles: ["Admin"],
          children: null,
        },
        {
          icon: "mdi-chart-scatter-plot-hexbin",
          title: "Thống kê",
          to: PATH.STATISTIC,
          roles: ["Admin"],
          children: null,
        },
      ],
    };
  },

  async created() {
    this.mini = true;
    try {
      const data = await this.$services.commonService.getUserRole();
      this.role = data?.userRole;
      this.$store.commit("user/setUserRole", this.role);
    } catch (err) {
    } finally {
      this.mini = false;
    }
  },

  computed: {
    ...mapState({
      currentStep: (state) => state.stepByStep.current,
    }),
    ...mapGetters({
      isGuideForUser: "isGuideForUser",
    }),
    isOpen: {
      get() {
        return this.$store.state.sidebar.isOpen;
      },
      set(val) {
        this.$store.commit("sidebar/TOGGLE_SIDEBAR", val);
      },
    },
    computedItems() {
      return this.items.filter((element) => element.roles.includes(this.role));
    },
    profile() {
      return {
        avatar: true,
        title: "avatar",
      };
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
    tooltipContent() {
      let retValue = {};
      if (this.isGuideForUser) {
        retValue = STEP_BY_STEP.find(
          (element) => element.path === this.computedItems[this.currentStep]?.to
        );
      }
      return retValue?.content || EMPTY;
    },
    nextStep() {
      return this.computedItems.length - 1 === this.currentStep
        ? null
        : this.currentStep + 1;
    },
  },

  methods: {
    ...mapMutations("sidebar", {
      toggleSidebar: "TOGGLE_SIDEBAR",
    }),
    onClickExpand() {
      console.log("🚀 ~ file: SideBar.vue:290 ~ onClickExpand ~ onClickExpand:");
      this.mini = !this.mini;
      this.toggleSidebar(!this.isOpen);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/tools/_rtl.sass'
.v-list-item
  font-size: 18px

.v-list-item--active
  color: black !important
  background-color: #c8c8c8 !important
  font-weight: bold

.v-list-item--link:before
  background-color: transparent !important

.brand
  font-family: 'Mabella' !important
  font-size: 30px !important
</style>
